document.addEventListener("DOMContentLoaded", function () {

    let userAgent = navigator.userAgent.toLowerCase();
    let iphone = /iphone/gi.test(userAgent);
    if (iphone) {
        $('html').addClass('iphone-support');
    }
    // choices
    const choicesArr = document.querySelectorAll(".js-choices");
    if (choicesArr) {
        choicesArr.forEach(select => {
            new Choices(select, {
                allowHTML: true,
                searchEnabled: false,
            });
        });
    }

    if (document.querySelector(".choices")) {
        const choicesWrappers = document.querySelectorAll(".choices");
        choicesWrappers.forEach(choiceWrap => {
            choiceWrap.addEventListener("click", () => {
                if (!choiceWrap.querySelector(".custom-simplebar-wrapper")) {
                    const selectSimplebar = choiceWrap.querySelector(
                        ".choices__list--dropdown .choices__list"
                    );
                    const div = document.createElement("div");
                    div.classList.add("custom-simplebar-wrapper");
                    const content = selectSimplebar.innerHTML;
                    selectSimplebar.innerHTML = "";
                    div.setAttribute("data-simplebar", "");
                    div.innerHTML = content;
                    selectSimplebar.appendChild(div);
                }
            });
        });
    }
    // end choices


    /*if (document.querySelector('.js-typeit')) {
        new TypeIt(".js-typeit", {
            speed: 100,
            loop:true
        })
        .type('a better future').pause(2000).delete()
        .type('a sustainable future').pause(2000).delete()
        .type('an equitable future').pause(2000).delete()
        .type('a just future').pause(2000).delete()
        .type('a healthy future').pause(2000).delete()
        .type('a compassionate future').pause(2000).delete()
        .type('a prosperous future').pause(2000).delete()
        .go();
    }*/

    let typeItContainer = $('.js-type-it-container');
    if (typeItContainer.length) {
        let typeItArr = $('.type-it-arr div');
        let typeItDuration = 2500;
        let typeItIndex = 0;
        let typeItUInterval = setInterval(function () {
            let currentSpan;
            typeItContainer.fadeOut(function () {
                let html = typeItArr.eq(typeItIndex).html()
                typeItContainer.fadeOut(function () {
                    $(this).html(html);
                    $(this).fadeIn();
                })

                typeItIndex++
                if (typeItIndex === typeItArr.length - 1) {
                    typeItIndex = 0;
                }
            })
        }, typeItDuration)
    }

    if (document.querySelector(".js-tilt")) {
        const tiltElements = document.querySelectorAll(".js-tilt");

        tiltElements.forEach(tiltElement => {
            VanillaTilt.init(tiltElement, {
                max: 15,
                speed: 400,
                gyroscope: true,
                transition: true,
                easing: "cubic-bezier(.03,.98,.52,.99)",
            });
        })
    }

    AOS.init({
        startEvent: 'load',
        offset: 150,
        delay: 0,
        duration: 700,
        easing: "ease",
        once: true,
        useClassNames: true,
    });

    function setAnimationMultiplyDelay(element, startDelay, defaultDelayStep, breakDelay) {
        let targetArr = $(`${element}`);
        let delay = startDelay ? startDelay : 0;
        let delayStep = defaultDelayStep ? defaultDelayStep : 0;
        let counter = 0;
        if (!delayStep) return;
        targetArr.each((idx, el) => {
            if (el) {
                $(el).attr('data-aos-delay', delay);
                delay += defaultDelayStep;
                if (breakDelay) {
                    counter++
                    if (counter === breakDelay) {
                        delay = 0;
                        counter = 0;
                    }
                }
            }
        });
    }

    // fix ios height used for hero section < 641px
    if (innerWidth < 1024) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

//fix mobile menu height because of bottom system navigation panel


    let navbar = document.querySelector('.header__navbar-box');
    if (navbar) {
        let vh = window.innerHeight * 0.01;
        navbar.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            navbar.style.setProperty('--vh', `${vh}px`);
        })
    }

    // end fix ios height

    // burger-menu
    const burgerMenu = (menuSelector, burgerSelector) => {
        let scrollTop = $(window).scrollTop();
        const menuElem = document.querySelector(menuSelector),
            layoutElem = document.querySelector(".header__layout"),
            burgerElem = document.querySelector(burgerSelector);

        const removeClass = () => {
            menuElem.classList.remove("active");
            scrollLock.clearQueueScrollLocks();
            scrollLock.enablePageScroll()
            if (iphone) {
                $(window).scrollTop(scrollTop)
                $('html').removeClass('open-menu');
            }

        };
        removeClass();

        menuElem.querySelectorAll("a").forEach(i => {
            i.addEventListener("click", e => {
                if (menuElem.classList.contains("active")) {
                    removeClass();
                }
            });
        });

        burgerElem.addEventListener("click", () => {
            if (iphone) {
                console.log('scrollTop', scrollTop)
                if (!menuElem.classList.contains("active")) {
                    scrollTop = $(window).scrollTop()
                    setTimeout(function () {
                        $('html').addClass('open-menu');

                    }, 500);
                }
                ;
            }

            if (menuElem.classList.contains("active")) {
                removeClass();
                if (iphone) {
                    $('html').removeClass('open-menu');
                    $(window).scrollTop(scrollTop)
                }
            } else {
                menuElem.classList.add("active");
                let scrollElement = document.querySelector('.header__navbar-box');
                let scrollableElement = document.querySelector('.header .simplebar-content-wrapper');
                if (!iphone) {
                    scrollLock.disablePageScroll(scrollElement);
                    scrollLock.addScrollableSelector(scrollableElement);
                }

            }
        });

        window.addEventListener("resize", () => {
            if (innerWidth > 1023) {
                removeClass();
            }
        });

        layoutElem.addEventListener("click", () => {
            removeClass();
        });
    };

    if (document.querySelector(".header")) {
        burgerMenu(".header", ".header__burger");
    }
    // end burger-menu

    // sticky

    function stickyMenu() {
        const headerElem = document.querySelector(".header");
        if (window.pageYOffset >= 10) {
            headerElem.classList.add("sticky");
        } else {
            headerElem.classList.remove("sticky");
        }
    }

    window.addEventListener("scroll", stickyMenu);
    stickyMenu();
    // end sticky

    // simplebar
    if (document.querySelector(".header__simplebar") && innerWidth < 1024) {
        new SimpleBar(document.querySelector(".header__simplebar"));
    }

    // end simplebar
    /*testimonials swiper start*/
    let tsSwiper = document.querySelector('.testimonials__slider');
    if (tsSwiper) {
        let swiper = new Swiper(tsSwiper, {
            spaceBetween: 30,
            effect: "fade",
            speed: 400,
            /*autoplay: {
                 delay: 3000,
                 disableOnInteraction: false,
                 pauseOnMouseEnter: true,
             },*/
            navigation: {
                nextEl: ".testimonials__next",
                prevEl: ".testimonials__prev",
            },
            /* on: {
                 afterInit: function () {
                     let talentPage = document.querySelector('.talent-page');
                     if (talentPage) {
                         /!* Read more button handler*!/
                         let slidesArr = tsSwiper.querySelectorAll('.swiper-slide');
                         slidesArr.forEach(slide => {
                             let tsContent = slide.querySelector('.testimonials__descr');
                             let tsContentText = tsContent.innerText;
                             let readMoreButton = `<span class="link-btn">Read more</span>`;
                             if (tsContentText.length > 220) {
                                 let newText = tsContentText.substring(0, 150); // short text
                                 tsContent.innerHTML = `<p>${newText.trim()}... ${readMoreButton}</p>`; // Shows short text
                                 // add all text to card for popup
                                 let textHidden = `
                                 <div class="testimonials__descr_hidden">
                                     <p>
                                     ${tsContentText}
                                     </p>
                                 </div>`
                                 tsContent.insertAdjacentHTML('beforeend', textHidden);
                             }
                         })
                     }
                 }
             }*/
        });

        /*testimonials popup handler*/
        /* window.closeTsPopup = function closeTsPopup() {
             let popup = document.querySelector('.testimonials-popup');
             $(popup).fadeOut(function () {
                 swiper.autoplay.start();
                 scrollLock.clearQueueScrollLocks();
                 scrollLock.enablePageScroll();
             });
         }
         window.showTsPopup = function showTsPopup(content, author) {
             swiper.autoplay.stop();
             let popup = document.querySelector('.testimonials-popup');
             let scrollContainer = popup.querySelector('.simplebar-content-wrapper');
             let popupDescr = popup.querySelector('.testimonials__descr');
             let popupAuthor = popup.querySelector('.testimonials__author');
             scrollLock.disablePageScroll(scrollContainer)
             $(popup).fadeIn({
                 start: function () {
                     $(this).css('display', 'flex');
                     popupDescr.innerHTML = content;
                     popupAuthor = author;
                 }
             });

         }
         $('body').on('click', '.testimonials__card .link-btn', function (e) {
             e.preventDefault();
             let tsCard = $(this).closest('.testimonials__card');
             let tsContent = tsCard.find('.testimonials__descr_hidden').html();
             let tsAuthor = tsCard.find('.testimonials__author').html();
             showTsPopup(tsContent, tsAuthor);
         })*/


        /*end of testimonials popup handler*/


    }
    /*End of testimonials swiper*/


    // mission
    if (document.querySelector('.js-scroll-anim')) {
        const mainWrappers = document.querySelectorAll('.js-scroll-anim');

        const addClassInSroll = () => {
            mainWrappers.forEach(wrapper => {
                if (wrapper.getBoundingClientRect().top < innerHeight / 2 && !wrapper.classList.contains('anim')) {
                    wrapper.classList.add('anim')
                }
            })
        }
        window.addEventListener('scroll', addClassInSroll);

        addClassInSroll();
    }

    // parallax
    if (document.querySelector('.js-parallax') && innerWidth > 1024) {
        const parallaxContainer = document.querySelectorAll(".js-parallax");
        parallaxContainer.forEach((elem) => {
            let parallaxInstance = new Parallax(elem);
            parallaxInstance.enable();
        });
    }

    function initTabs(tabArray, eventName, changeImage = false) {
        if (tabArray.length) {
            let imagesArr = [];
            if(changeImage){
                imagesArr =  $(tabArray[0]).closest('.do-tabs-body').find('.do-tabs-thumbnail');
            }
            tabArray.each((idx, tab) => {
                $(tab).on(eventName, function () {
                    if (!$(tab).hasClass('active')) {
                        tabArray.each((idx, el) => {
                            if (tab != el) {
                                $(el).parent().removeClass('active');
                                if (changeImage && imagesArr.length) {
                                    imagesArr.eq(idx).removeClass('active');
                                }
                            }
                        })
                        $(tab).parent().addClass('active');
                        if (changeImage && imagesArr.length) {
                            imagesArr.eq(idx).addClass('active');
                        }
                    }
                })
            })

            tabArray.eq(0).trigger(eventName)
        }
    }

    // let doTabItems = $('#do-tabs .do-tabs-item h3');
    // let valueTabItems = $('#value-tabs .do-tabs-item h3');
    // let purposeTabItems = $('#purpose-tabs .do-tabs-item h3');
    // let purposeTabItems2 = $('#purpose-tabs-2 .do-tabs-item h3');

    // initTabs(doTabItems, 'mouseenter');
    // initTabs(valueTabItems, 'mouseenter');
    // initTabs(purposeTabItems, 'mouseenter', true);
    // initTabs(purposeTabItems2, 'mouseenter', true);
    let tabs = $('[data-tabs]');
    tabs.each((idx, tabs)=>{
        let tabArray = $(tabs).find('.do-tabs-item h3');
        initTabs(tabArray, 'mouseenter', true);
    })





    let stepsSlider = $('.steps-slider-wr')
    if (stepsSlider.length) {
        let currentIdx = 0;
        let slideToggleArr = $('.steps-slider-toggle');
        let nextBtn = $('.steps-slider-btn-next');
        let prevBtn = $('.steps-slider-btn-prev');

        function nextSlide() {
            currentIdx++;
            if (currentIdx === slideToggleArr.length) {
                currentIdx = 0;
            }
            setSlide();
        }

        function prevSlide() {
            currentIdx--;
            if (currentIdx < 0) {
                currentIdx = slideToggleArr.length - 1;
            }
            setSlide();
        }

        function setSlide() {
            slideToggleArr.each((idx, el) => {
                if (idx === currentIdx) {
                    $(el).parent().addClass('active')
                    $(el).next().stop(false, true).slideDown();
                    // $(el).find('span').fadeOut(100)
                } else {
                    $(el).parent().removeClass('active')
                    $(el).next().stop(false, true).slideUp();
                    // $(el).find('span').fadeIn(100)
                }
            })
        }


        slideToggleArr.each((idx, el) => {
            $(el).click(function () {
                if (!$(el).parent().hasClass('active')) {
                    let currentEl = el;
                    slideToggleArr.each((idx, el) => {
                        if (currentEl == el) {
                            currentIdx = idx;
                        }
                    })
                    setSlide();
                }
            })
        })

        nextBtn.click(nextSlide);

        prevBtn.click(prevSlide);

        slideToggleArr.eq(0).trigger('click');

    }

    let dtTumbnailSwiperWr = document.querySelectorAll('.dt-thumbnail-swiper-wr')
    dtTumbnailSwiperWr.forEach(el => {
        if (el) {
            let swiperEl = el.querySelector('.swiper');
            let nextEl = el.querySelector('.swiper-button-next')
            let prevEl = el.querySelector('.swiper-button-prev')
            let slidesCount = el.querySelectorAll('.swiper-slide').length;
            let swiper = new Swiper(swiperEl, {
                slidesPerView: 1,
                spaceBetween: 30,
                speed: 800,
                threshold: 10,
                loot: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: false,
                },
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                }
            })
        }

    })


    let partnersSwiperWr = document.querySelectorAll('.partners-swiper-wr')
    partnersSwiperWr.forEach(el => {
        if (el) {
            let thumbsSwiperEl = el.querySelector('.partners-thumbs-swiper');
            let cardsSwiperEl = el.querySelector('.partners-swiper');
            let nextEl = el.querySelector('.swiper-button-next')
            let prevEl = el.querySelector('.swiper-button-prev')
            let slidesCount = thumbsSwiperEl.querySelectorAll('.swiper-slide').length;
            let thumbSwiper = new Swiper(thumbsSwiperEl, {
                slidesPerView: 1,
                spaceBetween: 15,
                speed: 1000,
                threshold: 10,
                allowTouchMove: false,
                watchSlidesProgress: true,

            });
            let cardsSwiper = new Swiper(cardsSwiperEl, {
                slidesPerView: 1,
                spaceBetween: 30,
                speed: 1000,
                threshold: 10,
                loop: false,
                /*autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: false,
                },*/
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                },
                thumbs: {
                    swiper: thumbSwiper,
                },
            })
        }
    })

    let newsSwiperWr = document.querySelectorAll('.news-swiper-wr')
    newsSwiperWr.forEach(el => {
        if (el) {
            let swiperEl = el.querySelector('.swiper');
            let nextEl = el.querySelector('.swiper-button-next')
            let prevEl = el.querySelector('.swiper-button-prev')
            let slidesCount = el.querySelectorAll('.swiper-slide').length;
            let swiper = new Swiper(swiperEl, {
                slidesPerView: 2,
                spaceBetween: 20,
                speed: 800,
                threshold: 10,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: false,
                },
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                },
                breakpoints: {
                    200: {
                        loop: slidesCount > 1,
                        slidesPerView: 1,
                    },
                    641: {
                        loop: slidesCount > 2,
                        slidesPerView: 2,
                    },
                }
            })
        }
    });

    const autosizeTextareaElements = document.querySelectorAll(
        'textarea[data-autosize]'
    );

    function auto_grow(element) {
        element.style.height = "5px";
        element.style.height = element.scrollHeight + "px";
    }

    autosizeTextareaElements.forEach(el => {
        el.addEventListener('input', function () {
            auto_grow(el)
        })
    })

    //set wrapper to blogs content image
    let blogImages = $('.news-content-main img');
    blogImages.each((id, img) => {
        let containerWidth = 916;
        let imgWidth = ($(img).width() / containerWidth) * 100; //get percentage value
        let float = $(img).css('float');
        if (float != 'none') {
            $(img).wrap(`<div class="float-${float} wrapper-initialized" style="width: ${imgWidth}%"></div>`)
            $(img).css({
                    width: '100%',
                    height: 'auto'
                }
            )
        }
    })


    $('.header__loupe input').on('input', function () {
        if (this.value == '') {
            $(this).removeClass('active')
        } else {
            $(this).addClass('active')
        }
    })


    let blogFilters = $(".news-section-filters .btn");
    if (blogFilters.length) {
        blogFilters.each((idx, el) => {
            $(el).on("click", function (e) {
                let currentIdx = idx;
                $(el).toggleClass("active");
                blogFilters.each((idx, el) => {
                    if (idx !== currentIdx) {
                        $(el).removeClass("active");
                    }
                });
            });
        });
    }

    let moreResourcesSwiperWr = document.querySelectorAll(".more-resources-swiper-wr");
    moreResourcesSwiperWr.forEach((el) => {
        if (el) {
            let swiperEl = el.querySelector(".swiper");
            let nextEl = el.querySelector(".swiper-button-next");
            let prevEl = el.querySelector(".swiper-button-prev");
            let slidesCount = el.querySelectorAll(".swiper-slide").length;
            let swiper = new Swiper (swiperEl, {
                slidesPerView: "auto",
                spaceBetween: 10,
                speed: 800,
                threshold: 10,
                direction: "horizontal",
                loop: false,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                },
                autoHeight: true,
                breakpoints: {
                    641: {
                         direction: "vertical",
                        autoHeight: false,
                    },
                },
                on: {
                    afterInit: function(swiper){
                        if(slidesCount<2){
                            swiper.el.closest('.more-resources-swiper-wr').classList.add('hide-gradient-layer')
                        }
                    },
                    reachEnd: function(swiper){
                        if(slidesCount>=2) {
                            swiper.el.closest('.more-resources-swiper-wr').classList.add('hide-gradient-layer')
                        }
                    },

                    slideChange:function(swiper){
                        if(slidesCount>=2) {
                            swiper.el.closest('.more-resources-swiper-wr').classList.remove('hide-gradient-layer')
                        }
                    },
                }
            });
        }
    });

}); // end of ready

